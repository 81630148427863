import Page from "../../components/Pages";
import ArticlesList from "../../sections/ArticlesPage/ArticlesList";

function ArticlesPage() {
  return (
    <Page title="Articles">
      <ArticlesList/>
    </Page>
  );
}

export default ArticlesPage;
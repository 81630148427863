import { LoadingButton } from "@mui/lab";
import { styled, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dateConverter from "../../common/dateConverter";
import ImageSelector from "../../components/ImageSelector/ImageSelector";
import { ArticleModel } from "../../models/ArticleModel";
import { addArticle, updateArticle } from "../../redux/slices/articles";
import { useAppDispatch } from "../../redux/store";
import { getFileUrl, sendFile } from "../../services/storage";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ArticleDetailsFormContainer = styled("form")(() => ({
    marginTop: "50px"
}));
interface Props {
    article?: ArticleModel
}

var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],

    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
  
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    ['link', 'image'],
    [{ 'align': [] }],

  ];

function ArticleDetailsForm({article}: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [cover, setCover] = useState("");
    const [category, setCategory] = useState("");
    const [content, setContent] = useState("");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(article) {
            setDate(dateConverter({timestamp: article.date, action: "toDateString"})?.toString() ?? "");
            setOrderDate(dateConverter({timestamp: article.orderDate, action: "toDateString"})?.toString() ?? "");
            setTitle(article.title);
            setSubtitle(article.subtitle);
            setCover(article.cover);
            setCategory(article.category);
            setContent(article.content);
        }
        // eslint-disable-next-line
    }, [article]);

    const add = () => {
        if(cover) {
            setIsLoading(true);
            sendFile(cover, title, "data_url").then(theCover => {
                getFileUrl(theCover.metadata.fullPath).then(coverUrl => {
                    dispatch(addArticle({article: {
                        cover: coverUrl,
                        category,
                        title,
                        subtitle,
                        content,
                        date: Number.parseInt(dateConverter({date: new Date(date), action: "toTimesTamp"})!.toString()),
                        orderDate: Number.parseInt(dateConverter({date: new Date(orderDate), action: "toTimesTamp"})!.toString()),
                    }})).then(() => {
                        navigate("/articles");
                    }).catch(() => {
                        alert("Remplissez convenablement le formulaire")
                    });
                });
            });
        }
        else
            alert("Remplissez convenablement le formulaire");
    }

    const update = (coverUrl: string) => {
        dispatch(updateArticle({article: {
            id: article?.id,
            cover: coverUrl,
            category,
            title,
            subtitle,
            content,
            date: Number.parseInt(dateConverter({date: new Date(date), action: "toTimesTamp"})!.toString()),
            orderDate: Number.parseInt(dateConverter({date: new Date(orderDate), action: "toTimesTamp"})!.toString()),
        }})).then(() => {
            navigate("/articles");
        }).catch(() => {
            alert("Remplissez convenablement le formulaire")
        });
    }

    const edit = () => {
        setIsLoading(true);
        if(article?.cover !== cover) {
            sendFile(cover, title, "data_url").then(theCover => {
                getFileUrl(theCover.metadata.fullPath).then(coverUrl => {
                    update(coverUrl);
                });
            });
        } else {
            update(article!.cover);
        }
    }

    const submit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if(article) {
            edit();
        }else {
            add();
        }
        
    }
  
    const handleTitleChange = (event: any) => setTitle(event.target.value);
    const handleSubTitleChange = (event: any) => setSubtitle(event.target.value);
    const handleCategoryChange = (event: any) => setCategory(event.target.value);
    const handleDateChange = (event: any) => setDate(event.target.value);
    const handleOderDateChange = (event: any) => setOrderDate(event.target.value);
    const handleCoverChange = (image: string) => setCover(image);
    const handleContentChange = (data: any) => setContent(data);

    return (
        <ArticleDetailsFormContainer onSubmit={submit}>
            <ImageSelector title="Couveture" image={cover} onChange={handleCoverChange} sx={{marginBottom: "50px"}}/>
            <TextField value={title} onChange={handleTitleChange} fullWidth size="small" label="Titre" type="text" helperText=" "/>
            <TextField value={subtitle} onChange={handleSubTitleChange} sx={{ marginTop: "20px" }} fullWidth size="small" label="Sous titre" type="text" helperText=" "/>
            <TextField value={category} onChange={handleCategoryChange} sx={{ marginTop: "20px" }} fullWidth size="small" label="Categorie" type="text" helperText=" "/>
            <TextField value={date} onChange={handleDateChange} sx={{ marginTop: "20px" }} fullWidth size="small" label="Date" type="date" helperText=" "/>
            <TextField value={orderDate} onChange={handleOderDateChange} sx={{ marginTop: "20px" }} fullWidth size="small" label="Date d'ordre" type="date" helperText=" "/>
            <ReactQuill modules={{toolbar: toolbarOptions}} theme="snow" value={content} onChange={handleContentChange}/>
            <LoadingButton sx={{ marginTop: "20px" }} fullWidth loading={isLoading} variant="contained" color="success" type="submit">{article ? "Mettre a jour" : "Ajouter"}</LoadingButton>
        </ArticleDetailsFormContainer>
    );
}

export default ArticleDetailsForm;
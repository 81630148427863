import { OTHER_PAGES, CLIENT_PAGES, DASHBOARD_PAGES } from "../routes/paths";

const OtherMenuItems = [
  {
    title: "404",
    path: OTHER_PAGES.page404,
  },
  {
    title: "500",
    path: OTHER_PAGES.page500,
  },
];

const ClinetMenuItems = [
  {
    title: "Connexion",
    path: CLIENT_PAGES.signIn
  },
];

const AdminMenuItems = [
  {
    title: "Dashboard",
    path: DASHBOARD_PAGES.dashboard
  },
  {
    title: "Articles",
    path: DASHBOARD_PAGES.articles
  },
];

export { OtherMenuItems, ClinetMenuItems, AdminMenuItems };

import { Navigate, useRoutes } from "react-router-dom";
import OthersLayout from "../layouts/OthersLayout";
import ClientLayout from "../layouts/ClientLayout";
import DashboardLayout from "../layouts/DashboardLayout";
import SignInPage from "../pages/client/SignInPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import { CLIENT_PAGES, DASHBOARD_PAGES } from "./paths";
import ArticlesPage from "../pages/dashboard/ArticlesPage";
import ArticleDetailsPage from "../pages/dashboard/ArticleDetailsPage";

export default function Router() {
    return useRoutes([
      // Others
      {
        path: "*",
        element: <OthersLayout/>,
        children: [
        //   { path: "500", element: <Page500/> },
        //   { path: "404", element: <NotFound/> },
        //   { path: "*", element: <Navigate to="/404" replace/> },
        ],
      },
      // Client
      {
        path: CLIENT_PAGES.signIn,
        element: <ClientLayout/>,
        children: [
          { element: <SignInPage/>, index: true },
        ],
      },
      // Dashboard
      {
        path: "/",
        element: <DashboardLayout/>,
        children: [
          { path: DASHBOARD_PAGES.dashboard, element: <DashboardPage/> },
          { path: DASHBOARD_PAGES.articles, element: <ArticlesPage/> },
          { path: DASHBOARD_PAGES.articleDetails, element: <ArticleDetailsPage/> },
        ],
      },
      // Redirection
      { path: "*", element: <Navigate to="/404" replace/> },
    ]);
  }
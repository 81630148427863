import { Box, styled, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import { signInWithEmailAndPassword } from "../../redux/slices/auth";
import { useAppDispatch } from "../../redux/store";

const SignInFormContainer = styled("div")(({ theme }) => ({
    width: "400px",
    height: "600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    [theme.breakpoints.down("lg")]: {
        width: "350px",
        height: "500px",
    },
}));

function SignInForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
  
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onSubmit = (event: React.SyntheticEvent) => {
      event.preventDefault();
      setIsLoading(true);
      dispatch(signInWithEmailAndPassword({email, pwd})).unwrap().then((res) => {
        navigate("/dashboard");
      }).catch(error => {
        console.log(error);
      });
    };
  
    const handleEmailChange = (event: any) => setEmail(event.target.value);
    const handlePwdChange = (event: any) => setPwd(event.target.value);
  
    return (
        <SignInFormContainer>
            <Logo title={"Free Market Group"} disableLink={true} sx={{width: 350, height: 300}}/>
            <Box component="form" sx={{ m: 1, width: "90%"}} noValidate autoComplete="off" onSubmit={onSubmit}>
                <TextField value={email} onChange={handleEmailChange} fullWidth size="small" label="Email" helperText=" "/>
                <TextField value={pwd} onChange={handlePwdChange} sx={{ marginTop: "20px" }} fullWidth size="small" label="Mot de passe" type="password" helperText=" "/>
                <LoadingButton sx={{ marginTop: "20px" }} fullWidth loading={isLoading} variant="contained" color="success" type="submit">Valider</LoadingButton>
            </Box>
        </SignInFormContainer>
    );
}

export default SignInForm;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ArticleModel } from "../../models/ArticleModel";
import * as articlesService from "../../services/firestore/articles";

// Thunks
export const getArticles = createAsyncThunk(
    "articles/getArticles", () => articlesService.getArticles()
);
export const deleteArticle = createAsyncThunk<string, {id: string}>(
    "articles/deleteArticle", async (arg) => {
        const {id} = arg;
        return await articlesService.deleteArticle(id).then(() => id);
    }
);
export const addArticle = createAsyncThunk<ArticleModel, {article: ArticleModel}>(
    "articles/addArticle", async (arg) => {
        const {article} = arg;
        return await articlesService.addArticle(article).then((id) => {
            article.id = id;
            return article;
        });
    }
);
export const updateArticle = createAsyncThunk<ArticleModel, {article: ArticleModel}>(
    "articles/updateArticle", async (arg) => {
        const {article} = arg;
        return await articlesService.updateArticle(article).then(() => {
            return article;
        });
    }
);

interface AdvertisingState {
    articles: Array<ArticleModel>
};

const initialState: AdvertisingState = {
    articles: [],
};

const advertisingSlice = createSlice({
    name: "articles",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getArticles.fulfilled, (state, { payload }) => {
            state.articles = payload;
        });
        builder.addCase(deleteArticle.fulfilled, (state, { payload }) => {
            state.articles = state.articles.filter(article => article.id !== payload);
        });
        builder.addCase(addArticle.fulfilled, (state, { payload }) => {
            state.articles.push(payload);
        });
        builder.addCase(updateArticle.fulfilled, (state, { payload }) => {
            state.articles = state.articles.filter(article => article.id !== payload.id);
            state.articles.push(payload);
        });
    }
});

// export const { } = advertisingSlice.actions;
export default advertisingSlice.reducer;
import { Fragment } from "react";
import { styled, SxProps } from "@mui/material";
import { Helmet } from "react-helmet-async";

const PageBaseStyle = styled("div")(() => ({
}));
interface Props {
    children: React.ReactNode,
    title: string,
    sx?: SxProps
}

function Page({children, title = "", sx = {width:"calc(100vw - 240px)", marginTop: "80px", marginLeft:"200px", padding: "10px"}}: Props) {
    const finalTitle = title === "" ? "Free Market Group" : title+" | Free Market Group";

    return (
        <Fragment>
            <Helmet>
                <title>{finalTitle}</title>
            </Helmet>
            <PageBaseStyle sx={{ ...sx }}>
                {children}
            </PageBaseStyle>
        </Fragment>
    );
}

export default Page;
interface Props {
    timestamp?: number,
    date?: Date | null,
    action?: "toTimesTamp" | "toDateString" | "toDateHourString"
}

function dateConverter({timestamp = 0, date = null, action = "toTimesTamp"} : Props) {

    if(action === "toTimesTamp") {
        if(date != null)
            return date?.getTime();
        return ;
    }
    else if(action === "toDateString") {
        if(timestamp != null){
            const nDate = new Date(timestamp);
            let month = ""+nDate.getUTCMonth()+1;
            let day = ""+nDate.getDate();
            if(month.length === 1)
                month = "0"+month;
            if(day.length === 1)
                day = "0"+day;
            return nDate.getFullYear()+"-"+day+"-"+month;
        }
        return null;
    }
    else if(action === "toDateHourString") {
        if(timestamp != null){
            const nDate = new Date(timestamp);
            let month = ""+nDate.getUTCMonth()+1;
            let day = ""+nDate.getDate();
            if(month.length === 1)
                month = "0"+month;
            if(day.length === 1)
                day = "0"+day;
            return `${nDate.getFullYear()}-${(month)}-${nDate.getDate()} ${day}:${nDate.getMinutes()}`;
        }
        return null;
    }
    return null;
}

export default dateConverter;
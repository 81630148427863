import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PagesLoader from "../../components/PageLoader";
import Page from "../../components/Pages";
import { ArticleModel } from "../../models/ArticleModel";
import { getArticles } from "../../redux/slices/articles";
import { useAppSelector, RootState, useAppDispatch } from "../../redux/store";
import ArticleDetailsForm from "../../sections/ArticleDetailsPage/ArticleDetailsForm";

function ArticleDetailsPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [newArticle, setNewArticle] = useState("Loading");
    const articles = useAppSelector((state: RootState) => state.articles.articles);
    const params = useParams();
    const [article, setArticle] = useState<ArticleModel | undefined>(undefined);
  
    const dispatch = useAppDispatch();
  
    useEffect(() => {
        if(articles.length) {
            setArticle(articles.find(article => article?.id === params.id));
            setIsLoading(false);
        }else {
            dispatch(getArticles()).unwrap().then(() => {
                setIsLoading(false);
            });
        }

        if(!article && !isLoading) {
            setNewArticle("Ajouter un article");
        }
        // eslint-disable-next-line
    }, [isLoading]);
 
    return (
        <Page title={article?.title ?? newArticle}>
            <PagesLoader isLoading={isLoading}>
                <ArticleDetailsForm article={article} />
            </PagesLoader>
        </Page>
    );
}

export default ArticleDetailsPage;
import { styled } from "@mui/material";
import Page from "../../components/Pages";
import SignInForm from "../../sections/SignInPage/SignInForm";

const SignInPageContainer = styled("div")(() => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function SignInPage() {

  return (
    <Page title="Connexion" sx={{width: "100vw", marginLeft: "0px", padding: "0px"}}>
      <SignInPageContainer>
        <SignInForm/>
      </SignInPageContainer>
    </Page>
  );
}

export default SignInPage;

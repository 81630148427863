import { Button, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dateConverter from "../../common/dateConverter";
import PagesLoader from "../../components/PageLoader";
import Table from "../../components/Table/Table";
import { deleteArticle, getArticles } from "../../redux/slices/articles";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";

const ArticlesListContainer = styled("div")(() => ({
}));
const ArticleAddBtnContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end"
}));
const ArticleAddBtn = styled(Button)(() => ({
}));

function ArticlesList() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const articles = useAppSelector((state: RootState) => state.articles.articles);

  const actions = {
    "Edider": (id:string) => {
      navigate("/article/"+id);
    },
    "Supprimer": (id:string) => {
      console.log(id);
      setIsLoading(true);
      dispatch(deleteArticle({id})).then(() => setIsLoading(false));
    },
  };
  

  useEffect(() => {
      if(articles.length) {
          setIsLoading(false);
      }else {
          dispatch(getArticles()).unwrap().then(() => {
              setIsLoading(false);
          });
      }
      // eslint-disable-next-line
  }, [isLoading]);
  
  return (
    <ArticlesListContainer>
      <PagesLoader isLoading={isLoading}>
          <ArticleAddBtnContainer>
            <ArticleAddBtn onClick={() => navigate("/article/0")}>Ajouter article</ArticleAddBtn>
          </ArticleAddBtnContainer>
          <Table data={ articles} headElements={[{name: "title", regularName: "Titre"},{name: "subtitle", regularName: "Description"},{name: "date", regularName: "Date", transform: (date: number) => dateConverter({timestamp: date, action: "toDateString"})}]} id="id" actions={actions}/>
      </PagesLoader>
    </ArticlesListContainer>
  );
}

export default ArticlesList;
import { ArticleModel } from "../../../models/ArticleModel";
import * as firestore from "../firestore";

const articlesRef = firestore.collection(firestore.firestore, "articles");

export const getArticles = async (): Promise<Array<ArticleModel>> => {
    return (await firestore.getDocs(articlesRef)).docs.map(doc => {
        let article = doc.data() as ArticleModel;
        article.id = doc.id;
        return article;
    });
};

export const deleteArticle = async (id:string): Promise<void> => {
    return (await firestore.deleteDoc(firestore.doc(articlesRef, id)));
};

export const addArticle = async (article: ArticleModel): Promise<string> => {
    return (await firestore.addDoc(articlesRef, article)).id;
};

export const updateArticle = async (article: any): Promise<void> => {
    const articleRef = firestore.doc(articlesRef, article.id);
    let art = {...article}
    delete art.id;
    return (await firestore.updateDoc(articleRef, art));
};

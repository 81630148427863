export const OTHER_PAGES = {
    page404: "/404",
    page500: "/500",
};
export const CLIENT_PAGES = {
    signIn: "/",
};
export const DASHBOARD_PAGES = {
    dashboard: "/dashboard",
    articles: "/articles",
    articleDetails: "/article/:id",
};